import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyDz0JsjgKPsIzlpHuPOLLCzxVsyuh2HBfk",
    authDomain: "mymoney-55083.firebaseapp.com",
    projectId: "mymoney-55083",
    storageBucket: "mymoney-55083.appspot.com",
    messagingSenderId: "1087826683299",
    appId: "1:1087826683299:web:fb499eb875e69393a3e616"
  };


  firebase.initializeApp(firebaseConfig)


  const  projectFirestore = firebase.firestore()
  const projectAuth = firebase.auth()

  const timestamp = firebase.firestore.Timestamp

  export {projectFirestore, projectAuth, timestamp}