import { useState, useEffect } from 'react'
import { projectAuth } from '../firebase/config'
import { useAuthContext } from './useAuthContext'

export const useLogout = () => {
    const [isCancelled, setIsCancelled] = useState(false)
    const [error,setError] = useState(null)
    const [isPending,setIsPending] = useState(null)
    const { dispatch } = useAuthContext()

    const logout = async () =>{
        setError(null)
        setIsPending(true)


        try{
            await projectAuth.signOut()

            dispatch({type: 'LOGOUT'})

            if(!isCancelled){
                setIsPending(false)
                setError(false)
            }

        } catch(err) {
            if(!isCancelled){
                console.log(err.message)
                setError(err.message)
                setIsPending(false)
            }

        }
    }
    //cleanup function fires on umnount
    useEffect(() => {
        return () => setIsCancelled(true)
    },[])

    return {error, isPending, logout }
}