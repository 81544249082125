import { useState, useEffect } from 'react'
import { projectAuth } from '../firebase/config'
import { useAuthContext } from './useAuthContext'

export const useLogin = (email,password) => {
    const [isCancelled, setIsCancelled] = useState(false)
    const [error,setError] = useState(null)
    const [isPending,setIsPending] = useState(null)
    const { dispatch } = useAuthContext()

    const login = async () =>{
        setError(null)
        setIsPending(true)


        try{
            const res = await projectAuth.signInWithEmailAndPassword(email,password)

            dispatch({type: 'LOGIN', payload: res.user})

            if(!isCancelled){
                setIsPending(false)
                setError(false)
            }

        } catch(err) {
            if(!isCancelled){
                console.log(err.message)
                setError(err.message)
                setIsPending(false)
            }

        }
    }
    //cleanup function fires on umnount
    useEffect(() => {
        return () => setIsCancelled(true)
    },[])

    return {error, isPending, login }
}