import { useState, useEffect } from 'react'
import { projectAuth } from '../firebase/config'
import { useAuthContext } from './useAuthContext'


export const useSignup = () => {
    const [isCancelled, setIsCancelled] =useState(false)
    const [error,setError] = useState(null)
    const [isPending,setIsPending] = useState(null)
    const { dispatch } = useAuthContext()

    const signup = async (email,password,displayName) => {
        setError(null)
        setIsPending(true)

        try {
            //signup user
           const res = await projectAuth.createUserWithEmailAndPassword(email,password)
            

            if(!res){
                throw new Error('Could not complete signup')
            }

            await res.user.updateProfile({ displayName })

            //dispatch loging action
            dispatch({type: 'LOGIN', payload: res.user})

            if(!isCancelled){
                setIsPending(false)
                setError(null)
            }

        } catch (err) {
            if(!isCancelled){
                console.log(err)
                setError(err.message)
                setIsPending(false)
            }
        }
 
    

    }

     //cleanup function fires on umnount
     useEffect(() => {
        return () => setIsCancelled(true)
    },[])

    return {error, isPending, signup}

}